import React, { useEffect, useRef, useCallback } from 'react'
// Import Scrollbar
import SimpleBar from 'simplebar-react'

// MetisMenu
import MetisMenu from 'metismenujs'

import { Link } from 'react-router-dom'

// i18n
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import withRouter from '../../Components/Common/withRouter'
import { user } from '../../slices/auth/login/reducer'

const SidebarContent = (props: any) => {
  const ref = useRef<any>()
  const activateParentDropdown = useCallback((item: any) => {
    item.classList.add('active')
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show')
    }

    if (parent) {
      parent.classList.add('mm-active')
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add('mm-show') // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add('mm-active') // li
          parent3.childNodes[0].classList.add('mm-active') // a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add('mm-show') // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add('mm-show') // li
              parent5.childNodes[0].classList.add('mm-active') // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = (items) => {
    for (let i = 0; i < items.length; ++i) {
      const item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains('active')) {
        item.classList.remove('active')
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.remove('mm-show')
        }

        parent.classList.remove('mm-active')
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove('mm-show')

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove('mm-active') // li
            parent3.childNodes[0].classList.remove('mm-active')

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove('mm-show') // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove('mm-show') // li
                parent5.childNodes[0].classList.remove('mm-active') // a tag
              }
            }
          }
        }
      }
    }
  }

  const activeMenu = useCallback(() => {
    const pathName = process.env.PUBLIC_URL + props.router.location.pathname
    let matchingMenuItem = null
    const ul: any = document.getElementById('side-menu')
    const items = ul.getElementsByTagName('a')
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [props.router.location.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu('#side-menu')
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    activeMenu()
  }, [activeMenu])

  const userData: any = useSelector(user)
  const isAdmin = userData?.roles?.includes('ADMIN')

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <SimpleBar className="h-100" ref={ref}>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{props.t('Menu')} </li>
          <li>
            <Link to="/#" className="has-arrow">
              <i className="bx bx-home-circle" />
              <span>{props.t('Dashboards')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/dashboard">TROUVER UN PRODUIT</Link>
              </li>
              <li>
                <Link to="/reverse">RECHERCHE INVERSÉE</Link>
              </li>
              {userData?.plan !== 'FREE' || user?.roles?.includes('ADMIN') ? (
                <li>
                  <Link to="/suppliers">LISTE DES FOURNISSEURS</Link>
                </li>
              ) : null}
              <li />
              {/* <li>
                <Link to="/newScann">AJOUTER UN SCAN</Link>
              </li> */}
              {userData?.roles?.includes('ADMIN') ||
              userData?.roles?.includes('TESTER') ? (
                <li>
                  <Link to="/scans">LISTE DES SCANS</Link>
                </li>
              ) : null}
              <li />
              {/* <li>
                <Link to="#">{props.t('Saas')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Crypto')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Blog')}</Link>
              </li>
              <li>
                <Link to="#">
                  <span
                    className="badge rounded-pill text-bg-success float-end"
                    key="t-new"
                  >
                    New
                  </span>
                  {props.t('Jobs')}
                </Link>
              </li> */}
            </ul>
          </li>
          <li>
            <a
              href="https://www.resellcorner.io/tutoriel"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bx bx-calendar" />
              <span>TUTORIEL</span>
            </a>
          </li>
          <li>
            <Link to="/support">
              <i className="bx bx-chat" />
              <span>CONTACTER LE SUPPORT</span>
            </Link>
          </li>
          <li>
            <a
              href="https://chromewebstore.google.com/detail/resellcorner-asin-fetcher/bbjibbialgdojaohpiinbgijgochcoog"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bx bx-calendar" />
              <span>CHROME EXTENSION</span>
            </a>
          </li>

          {/* <li className="menu-title">TUTORIEL</li> */}

          {/* <li className="menu-title">{props.t('Apps')}</li>

          <li>
            <Link to="#">
              <i className="bx bx-calendar" />
              <span>{props.t('Calendar')}</span>
            </Link>
          </li>

          <li>
            <Link to="#">
              <i className="bx bx-chat" />
              <span>{props.t('Chat')}</span>
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="bx bx-file" />
              <span>{props.t('File Manager')}</span>
            </Link>
          </li> */}

          {/* <li>
            <Link to="#" className="has-arrow">
              <i className="bx bx-user" />
              <span>Profile</span>
            </Link>

            <ul className="sub-menu">
              <li>
                <Link to={`${process.env.PUBLIC_URL}/update-user`}>Profil</Link>
              </li>
              {isAdmin && (
                <>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/users`}>
                      Liste des utilisateurs
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/users`}>
                      {props.t('Alerts')}
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/users`}>
                      Editer une facture
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/users`}>
                      Upload Manuel
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/amazonapi`}>
                      Amazon API
                    </Link>
                  </li>{' '}
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/disclaimer`}>
                      Admin Message
                    </Link>
                  </li>{' '}
                </>
              )}
              <li>
                <Link to="/logout" className="dropdown-item">
                  <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                  <span>{props.t('Logout')}</span>
                </Link>
              </li>
            </ul>
          </li> */}
          {/*
          <li>
            <Link to="#" className="has-arrow ">
              <i className="bx bx-bitcoin" />
              <span>{props.t('Crypto')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="#">{props.t('Wallet')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Buy/Sell')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Exchange')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Lending')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Orders')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('KYC Application')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('ICO Landing')}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="#" className="has-arrow ">
              <i className="bx bx-envelope" />
              <span>{props.t('Email')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="#">{props.t('Inbox')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Read Email')} </Link>
              </li>
              <li>
                <Link to="#" className="has-arrow">
                  <span key="t-email-templates">{props.t('Templates')}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="#">{props.t('Basic Action')}</Link>
                  </li>
                  <li>
                    <Link to="#">{props.t('Alert Email')} </Link>
                  </li>
                  <li>
                    <Link to="#">{props.t('Billing Email')} </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <Link to="#" className="has-arrow ">
              <i className="bx bx-receipt" />
              <span>{props.t('Invoices')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="#">{props.t('Invoice List')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Invoice Detail')}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="#" className="has-arrow ">
              <i className="bx bx-briefcase-alt-2" />
              <span>{props.t('Projects')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="#">{props.t('Projects Grid')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Projects List')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Project Overview')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Create New')}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="#" className="has-arrow ">
              <i className="bx bx-task" />
              <span>{props.t('Tasks')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="#">{props.t('Task List')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Tasks Kanban')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Create Task')}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="#" className="has-arrow ">
              <i className="bx bxs-user-detail" />
              <span>{props.t('Contacts')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="#">{props.t('User Grid')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('User List')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Profile')}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="#" className="has-arrow">
              <i className="bx bxs-detail" />
              <span>{props.t('Blog')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="#">{props.t('Blog List')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Blog Grid')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Blog Details')}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="#">
              <span
                className="badge rounded-pill bg-success float-end"
                key="t-new"
              >
                New
              </span>
              <i className="bx bx-briefcase-alt" />
              <span key="t-jobs">{props.t('Jobs')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="#">{props.t('Job List')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Job Grid')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Apply Job')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Job Details')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Jobs Categories')}</Link>
              </li>
              <li>
                <Link to="#" className="has-arrow">
                  {props.t('Candidate')}
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="#">{props.t('List')}</Link>
                  </li>
                  <li>
                    <Link to="#">{props.t('Overview')}</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li className="menu-title">Pages</li>
          <li>
            <Link to="#" className="has-arrow">
              <i className="bx bx-user-circle" />
              <span>{props.t('Authentication')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="#">{props.t('Login')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Login 2')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Register')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Register 2')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Recover Password')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Recover Password 2')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Lock Screen')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Lock Screen 2')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Confirm Mail')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Confirm Mail 2')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Email Verification')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Email Verification 2')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Two Step Verification')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Two Step Verification 2')}</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="#" className="has-arrow ">
              <i className="bx bx-file" />
              <span>{props.t('Utility')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="#">{props.t('Starter Page')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Maintenance')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Coming Soon')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Timeline')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('FAQs')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Pricing')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Error 404')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Error 500')}</Link>
              </li>
            </ul>
          </li>

          <li className="menu-title">{props.t('Components')}</li> */}

          {/* <li>
            <Link to="#" className="has-arrow">
              <i className="bx bx-tone" />
              <span>{props.t('UI Elements')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="#">{props.t('Alerts')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Buttons')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Cards')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Carousel')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Dropdowns')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Grid')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Images')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Lightbox')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Modals')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('OffCanvas')}</Link>
              </li>

              <li>
                <Link to="#">{props.t('Range Slider')}</Link>
              </li>

              <li>
                <Link to="#">{props.t('Progress Bars')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Placeholders')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Tabs & Accordions')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Typography')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Toasts')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Video')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('General')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Colors')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Rating')}</Link>
              </li>
              <li>
                <Link to="#">{props.t('Notifications')}</Link>
              </li>
              <li>
                <Link to="#">
                  <span className="badge rounded-pill bg-success float-end">
                    {props.t('New')}
                  </span>
                  {props.t('Utilities')}
                </Link>
              </li>
            </ul>
          </li> */}
        </ul>
      </div>
    </SimpleBar>
  )
}
export default withRouter(withTranslation()(SidebarContent))

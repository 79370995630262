/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { COUNTRY_FLAGS, TVA_RATES } from 'common/utils'
import * as R from 'ramda'
import * as Store from 'slices'
import { user as userSelector } from 'slices/auth/login/reducer'
import { TProduct } from 'data/ProductEntity'
import { TSupplier } from 'data/SupplierEntity'

export const flags = {
  fr: '🇫🇷',
  uk: '🇬🇧',
  de: '🇩🇪',
  it: '🇮🇹',
  es: '🇪🇸',
  us: '🇺🇸',
  ca: '🇨🇦',
  pl: '🇵🇱',
  nl: '🇳🇱',
  se: '🇸🇪',
  jp: '🇯🇵',
  in: '🇮🇳',
}
export const ResultTable = ({
  tableResult,
  isLoading = false,
  allowNonProfit = false,
}) => {
  const [seletedPriceOption, setSelectedPriceOption] = useState({})
  const [modal, setModal] = useState(false)
  const [unmountOnClose, setUnmountOnClose] = useState(true)
  const supplierIndexes = useSelector(Store.suppliers.selectors.getIndexes)
  const user: any = useSelector(userSelector)

  const getAffilizzUrl = (id: string, url: string) => {
    const supplier = supplierIndexes[id] as unknown as TSupplier

    if (supplier?.hasAffiliation) {
      return `https://redirect.affilizz.com/r?u=${url}&m=66660bf51d631305a36a9f85`
    }
    return url
  }
  const toggle = () => setModal(!modal)
  const changeUnmountOnClose = (e) => {
    const { value } = e.target
    setUnmountOnClose(JSON.parse(value))
  }
  const [result, setResult] = React.useState(tableResult)
  React.useEffect(() => {
    setResult(tableResult)
  }, [tableResult])
  const copyTextToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    }
    return document.execCommand('copy', true, text)
  }
  const getPriceWithVat = (product: any) => {
    const tva = TVA_RATES[user.country]
    const supplier: any = supplierIndexes[product.supplier]
    const price = product.supplierPrice
    if (supplier._id === '65bd5600bc6de92a651f048b') {
      return price * (1 + tva / 100)
    }
    return price
  }
  const getPriceWithoutVat = (product: any) => {
    const tva = TVA_RATES[user.country] || 20
    const supplier: any = supplierIndexes[product.supplier]
    const price = product.supplierPrice
    if (supplier._id === '65bd5600bc6de92a651f048b') {
      return price
    }
    return price - price * (tva / 100)
  }

  const calculateProfit = (product: TProduct) => {
    const supplier = supplierIndexes[product.supplier] as any
    if (supplier._id !== '65bd5600bc6de92a651f048b') {
      return product.profit || 0
    }
    const amazonPriceVatExcl =
      (product.amazonPrice as number) / (1 + TVA_RATES.FR / 100)
    const referalFees = (product.amazonPrice as number) * 0.15

    return (
      amazonPriceVatExcl -
      product.supplierPrice -
      ((referalFees as number) + (product.fbaFee as number))
    )
  }

  const calculateProfitPerMonth = (product: TProduct) => {
    const supplier = supplierIndexes[product.supplier] as any
    if (supplier._id !== '65bd5600bc6de92a651f048b') {
      return product.profitPerMonth || 0
    }
    return calculateProfit(product) * (product.estimatedSalesPerMonth || 1)
  }
  const calculateRoi = (product: TProduct) => {
    const supplier = supplierIndexes[product.supplier] as any
    if (supplier._id !== '65bd5600bc6de92a651f048b') {
      return product.roi || 0
    }
    return (calculateProfit(product) / (product.supplierPrice * 1.2)) * 100
  }
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} unmountOnClose={unmountOnClose}>
        <ModalHeader toggle={toggle}>Option de prix</ModalHeader>
        <ModalBody>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Country</th>
                <th>Supplier</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(seletedPriceOption).map((supplier) => {
                const item = seletedPriceOption[supplier][0]
                const displayName = (supplierIndexes[supplier] as any).name
                return (
                  <tr key={item._id}>
                    <td>
                      <div className="text-center" style={{ fontSize: 20 }}>
                        {COUNTRY_FLAGS[item.supplierCountry.toUpperCase()]}
                      </div>
                    </td>
                    <td>
                      <div className="text-center lead">{displayName}</div>
                    </td>
                    <td>
                      <div className="font-weight-bold text-center">
                        {getPriceWithoutVat(item).toFixed(2)}€
                      </div>
                      <div className=" text-center">VAT Excl</div>
                      <div className="font-weight-bold text-center mt-4">
                        {getPriceWithVat(item).toFixed(2)} €
                      </div>
                      <div className="text-center">VAT Incl</div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>ASIN/EAN</th>
            <th>Titre Amazon</th>
            <th>Photo Amazon</th>
            <th>Photo Fournisseur</th>
            <th>Prix Amazon</th>
            <th>Prix Fournisseurs</th>
            <th>Profit Unitaire</th>
            <th>ROI à l'unité</th>
            <th>Vente/mois</th>
            <th>Profit/mois</th>
            <th>Graph Keepa</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={11} className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          )}
          {Object.keys(result).length === 0 && (
            <tr>
              <td colSpan={11} className="text-center">
                Pas de résultat
              </td>
            </tr>
          )}
          {isLoading
            ? null
            : Object.keys(result).map((asin) => {
                const items = result[asin].products

                const item = items[0]
                const allNeg = items.every((_item) => _item.profit < 0)
                if (allNeg && allowNonProfit === false) {
                  return null
                }
                return (
                  <tr key={`${item.asin}-bigrow`}>
                    <td>
                      <button
                        className="btn btn-link"
                        type="button"
                        onClick={() => {
                          copyTextToClipboard(item.asin)
                        }}
                      >
                        <i className="bx bx-book font-size-16 align-middle me-2" />
                        ASIN: {item.asin}
                      </button>
                      {item.ean && (
                        <button
                          className="btn btn-link"
                          type="button"
                          onClick={() => {
                            copyTextToClipboard(item.ean)
                          }}
                        >
                          <i className="bx bx-book font-size-16 align-middle me-2" />
                          EAN: {item.ean}
                        </button>
                      )}
                      {item.upc && (
                        <button
                          className="btn btn-link"
                          type="button"
                          onClick={() => {
                            copyTextToClipboard(item.upc)
                          }}
                        >
                          <i className="bx bx-book font-size-16 align-middle me-2" />
                          UPC: {item.upc}
                        </button>
                      )}
                      <div className="text-center">
                        <a
                          target="_blank"
                          href={`https://sas.selleramp.com/sas/lookup?SasLookup[search_term]=${
                            item.asin
                          }&sas_cost_price=${parseFloat(
                            item.supplierPrice
                          ).toFixed(2)}`}
                          rel="noreferrer"
                        >
                          <img
                            src={require('../../assets/images/seller_amp.jpeg')}
                            alt="Seller AMP product"
                            style={{ width: '100px' }}
                          />
                        </a>
                      </div>
                    </td>
                    <td>
                      {items.map((productItem, itemProductItemKey) => (
                        <div
                          className="text-left mb-2"
                          key={
                            productItem.title +
                            productItem._id +
                            productItem.supplierCountry +
                            itemProductItemKey
                          }
                        >
                          <a
                            href={productItem.amazonUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {COUNTRY_FLAGS[productItem.amazonCountry]}{' '}
                            {productItem.title}
                          </a>
                        </div>
                      ))}
                    </td>
                    <td>
                      <div className="text-center">
                        <a
                          href={item.amazonUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={item.amazonImageUrl}
                            alt="Amazon product"
                            style={{ width: '100px' }}
                          />
                        </a>
                      </div>
                    </td>
                    <td>
                      {/* https://redirect.affilizz.com/r?u=${item.supplierUrl}&m=66660bf51d631305a36a9f85 */}
                      <a
                        href={getAffilizzUrl(item.supplier, item.supplierUrl)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={item.amazonImageUrl}
                          alt="Amazon product"
                          style={{ width: '100px' }}
                        />
                      </a>
                    </td>
                    <td>
                      <div className="text-center">
                        {items.map((productItem) => (
                          <div
                            key={productItem.amazonPrice + productItem._id}
                            className="font-weight-bold text-center mb-2"
                          >
                            {COUNTRY_FLAGS[productItem.amazonCountry]}{' '}
                            {parseFloat(productItem.amazonPrice).toFixed(2)}€
                          </div>
                        ))}
                      </div>
                    </td>
                    <td>
                      <div className="font-weight-bold text-center">
                        {getPriceWithoutVat(item).toFixed(2)}€
                      </div>
                      <div className=" text-center">VAT Excl</div>
                      <div className="font-weight-bold text-center mt-4">
                        {getPriceWithVat(item).toFixed(2)} €
                      </div>
                      <div className="text-center">VAT Incl</div>
                      <div>
                        <button
                          onClick={() => {
                            const selecteds = R.groupBy(
                              R.prop('supplier') as any,
                              items
                            )
                            setSelectedPriceOption(selecteds)
                            // setSelectedPriceOption('vat_incl')
                            toggle()
                          }}
                          className="btn btn-primary mt-5"
                          type="button"
                        >
                          {/* <i className="bx bx-book font-size-16 align-middle me-2" /> */}
                          Option de prix
                        </button>
                      </div>
                    </td>
                    <td>
                      {items.map((productItem, key) => (
                        <div
                          key={productItem.profit + productItem._id + key}
                          className="font-weight-bold text-center mb-2"
                        >
                          {COUNTRY_FLAGS[productItem.amazonCountry]}{' '}
                          {parseFloat(
                            `${calculateProfit(productItem as any)}`
                          ).toFixed(2)}{' '}
                          €
                        </div>
                      ))}
                    </td>
                    <td>
                      {items.map((productItem, key) => (
                        <div
                          key={productItem.roi + productItem._id + key}
                          className="font-weight-bold text-center mb-2"
                        >
                          {COUNTRY_FLAGS[productItem.amazonCountry]}{' '}
                          {parseFloat(`${calculateRoi(productItem)}`).toFixed(
                            2
                          )}{' '}
                          %
                        </div>
                      ))}
                    </td>
                    <td>
                      {items.map((productItem, key) => (
                        <div
                          key={
                            productItem.estimatedSales + productItem._id + key
                          }
                          className="font-weight-bold text-center mb-2"
                        >
                          {COUNTRY_FLAGS[productItem.amazonCountry]}{' '}
                          {parseFloat(productItem.estimatedSales).toFixed(0)}/mo
                        </div>
                      ))}
                      <div className="text-center">
                        Total:{' '}
                        {parseFloat(
                          items
                            .reduce(
                              (acc, _item) =>
                                acc + parseFloat(_item.estimatedSales),
                              0
                            )
                            .toFixed(0)
                        )}
                        /mo
                      </div>
                    </td>
                    <td>
                      {items.map((productItem, key) => (
                        <div
                          key={`${productItem.profitPerMonth}-${key}`}
                          className="font-weight-bold text-center mb-2"
                        >
                          {COUNTRY_FLAGS[productItem.amazonCountry]}{' '}
                          {parseFloat(
                            `${calculateProfitPerMonth(productItem)}`
                          ).toFixed(2)}{' '}
                          €
                        </div>
                      ))}
                      <div className="text-center">
                        Total:{' '}
                        {parseFloat(
                          items
                            .reduce(
                              (acc, _item) =>
                                acc + parseFloat(_item.profitPerMonth),
                              0
                            )
                            .toFixed(2)
                        )}
                        €/mo
                      </div>
                    </td>
                    <td>
                      {items.map((productItem, key) => (
                        <div
                          key={`${productItem.profit}-${
                            productItem._id
                          }-${key}${productItem.amazonCountry?.toLowerCase()}`}
                          className="font-weight-bold text-center mb-2"
                        >
                          <a
                            href={`https://keepa.com/#!product/2-${productItem.asin}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="text-center">
                              {COUNTRY_FLAGS[productItem.amazonCountry]}
                            </div>
                            <img
                              src={`https://graph.keepa.com/pricehistory.png?asin=${
                                productItem.asin
                              }&domain=${(
                                productItem.amazonCountry ||
                                productItem.country ||
                                ''
                              ).toLowerCase()}`}
                              alt="Keepa Graph"
                              style={{ width: '100px' }}
                            />
                          </a>
                        </div>
                      ))}
                    </td>
                  </tr>
                )
              })}
        </tbody>
      </Table>
    </div>
  )
}

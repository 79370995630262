import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

// given a number return an array of numbers from 3 number before to 3 number after the given number betwen interval 1 and max
const getPaginationArray = (currentPage: number, max: number) => {
  const start = currentPage - 3 > 0 ? currentPage - 3 : 1
  const end = currentPage + 3 < max ? currentPage + 3 : max
  return Array.from({ length: end - start + 1 }, (_, i) => start + i)
}
const CustomPagination = ({
  hastNextPage,
  currentPage,
  hasPrevPage,
  handlePageClick,
}) => {
  let pagesCount = 0
  if (hasPrevPage && hastNextPage) {
    pagesCount = currentPage + 2
  } else if (hasPrevPage || hastNextPage) {
    pagesCount = currentPage + 1
  }
  return (
    <Pagination>
      <PaginationItem disabled={currentPage <= 1}>
        {hasPrevPage && currentPage > 2 && (
          <PaginationLink
            onClick={(e) => handlePageClick(e, 1)}
            first
            href="#"
            style={{ cursor: 'pointer', fontSize: '20px' }}
          />
        )}
      </PaginationItem>
      <PaginationItem disabled={currentPage <= 1}>
        {hasPrevPage && (
          <PaginationLink
            onClick={(e) => handlePageClick(e, currentPage - 1)}
            previous
            href="#"
            style={{ cursor: 'pointer', fontSize: '20px' }}
          />
        )}
      </PaginationItem>
      {[...getPaginationArray(currentPage, pagesCount)].map((page) => (
        <PaginationItem active={page === currentPage} key={page}>
          <PaginationLink
            style={{ cursor: 'pointer', fontSize: '20px' }}
            onClick={(e) => handlePageClick(e, page)}
            href="#"
          >
            {page}
          </PaginationLink>
        </PaginationItem>
      ))}
      {hastNextPage && (
        <PaginationItem disabled={currentPage >= pagesCount}>
          <PaginationLink
            onClick={(e) => handlePageClick(e, currentPage + 1)}
            next
            href="#"
            style={{ cursor: 'pointer', fontSize: '20px' }}
          />
        </PaginationItem>
      )}
    </Pagination>
  )
}

export { CustomPagination }
